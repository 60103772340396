@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track-piece {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

​table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #e5e7eb;
  text-align: left;
  padding: 3px;
}
html {
  scroll-behavior: smooth;
}
.p-confirm-dialog-accept {
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: green;
  color: white;
}
.p-confirm-dialog-reject {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: gray;
  color: white;
}
.p-autocomplete-panel {
  background-color: gray;
  color: white;
  font-family: "Rubik", sans-serif;
}
.p-autocomplete-item {
  border-bottom: 1px solid white;
  padding-left: 5px;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.section-title {
  align-items: center;
  color: #f97316;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
  justify-content: center;
  padding: 1rem;
  text-transform: uppercase;
}
.section-title:after,
.section-title:before {
  background: linear-gradient(270deg, #f97316, #f9731600);
  content: "";
  height: 1px;
  width: 20%;
}
.section-title:after {
  background: linear-gradient(90deg, #f97316, #f9731600);
}
.p-dialog-top .p-dialog,
.p-dialog-bottom .p-dialog,
.p-dialog-left .p-dialog,
.p-dialog-right .p-dialog,
.p-dialog-top-left .p-dialog,
.p-dialog-top-right .p-dialog,
.p-dialog-bottom-left .p-dialog,
.p-dialog-bottom-right .p-dialog {
  margin: 0px !important;
}
.quantity-controller {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  color: #333;
  display: inline-flex;
  flex-direction: row;
  height: 24px;
}
/* .p-dialog .p-dialog-content {
  padding: 1rem !important;
}
.p-dialog-footer {
  border-top: 1px solid #e5e7eb !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 0px !important;
} */
.swal2-title {
  font-size: 1.5rem !important;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s infinite;
}
.scrolled-tabs {
  overflow-x: auto;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrolled-tabs__wrapper {
  display: flex;
  white-space: nowrap;
}
.scrolled-tabs__wrapper > div {
  border-bottom-width: 2px;
  cursor: pointer;
  padding: 1rem 0.75rem;
  --tw-text-opacity: 1;
}

.capitalize {
  text-transform: capitalize;
}

.scrolled-tabs__wrapper > div.active {
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
}
.category-active {
  border-left-width: 2px;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.p-checkbox .p-checkbox-box {
  width: 30px !important;
  height: 30px !important;
}
.p-checkbox {
  width: 30px !important;
  height: 30px !important;
}
